import { useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import languageDetector from '@/helpers/languageDetector';
import { Button } from '@forma/forma-ui-kit';
import useClickOutside from '@/hooks/useClickOutside';

import styles from './lang-switch.module.css';

const LangSwitch = ({ className }) => {
  const containerRef = useRef();
  const { i18n, t } = useTranslation();
  const { pathname, query } = useRouter();
  const [ isOpen, setIsOpen ] = useState(null);

  useClickOutside(() => setIsOpen(false), containerRef);

  if (!i18n || !i18n.language || !i18n.options.locales) return null;

  const handleClickLang = (lang) => {
    languageDetector.cache(lang);
    setIsOpen(false);
  };

  return (
    <div className={classNames(styles.root, className)} ref={containerRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        viewStyle="text"
        className={styles.button}
        iconClassName={styles.buttonIcon}
        icon={<><ReactSVG src="/icons/language.svg" wrapper="span" /><span style={{ display: 'none' }}>{t('language')}</span></>}
        // title={t('language')}
      />
      <div className={classNames(styles.list, isOpen && styles.open)}>
        {i18n.options.locales.map(lang => {
          let href = pathname === '/' ? pathname + lang : pathname.replace('[locale]', lang);
          if (query.slug) href = href.replace('[slug]', query.slug);
          if (query.folders) {
            const stringFolders = query.folders.reduce((acc, elem) => (acc = acc + elem + '/'), '');
            href = href.replace('[...folders]', stringFolders);
          }
          return (
            <div className={styles.item} key={lang}>
              <Link
                href={href}
                onClick={() => handleClickLang(lang)}
                className={classNames(styles.link, i18n.language === lang && styles.active)}
                title={t('links.' + lang)}
              >
                {lang}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LangSwitch;
