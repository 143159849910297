import { useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const useClientI18next = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // init client i18next instance
    if (!i18next.language) i18next.init(i18n.options);
    // eslint-disable-next-line
  }, [i18next.language]);

  useEffect(() => {
    // change client instance language
    if (i18next.language) i18next.changeLanguage(i18n.language);
  }, [i18n.language]);
};

export default useClientI18next;
