import { createContext } from 'react';

export interface IMainContext {
  main?: {
    company_phone?: string,
    menu?: {
      title: string,
      items: {
        name: string,
        href: string,
        title: string
      }[]
    }[],
    footer?: {
      title: string,
      items: {
        name: string,
        href: string,
        title: string
      }[]
    }[]
  },
  home?: {},
  timezones?: []
};

const initialState = {};

export const MainContext = createContext<IMainContext>(initialState);