import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Link from '@/components/Link';
import Logo from '@/components/Logo';
import { MainContext } from '@/context/MainContext';

import styles from './footer.module.css';

const FooterColumn = ({ id, title, items }) => {
  return (
    <div className={styles.column} id={id}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.items}>
        {items.map(({ name, href, icon, title }, index) =>
          <div className={styles.item} key={index}>
            {href ? (
              <Link href={href} className={styles.link} title={title}>
                {icon && <ReactSVG className={styles.linkIcon} src={icon} wrapper="span" />} {name}
              </Link>
            ) : (
              <span className={classNames(styles.link, styles.cursorDefault)}>
                {icon && <ReactSVG className={styles.linkIcon} src={icon} wrapper="span" />} {name}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const { main } = useContext(MainContext);

  return (
    <footer className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.company}>
          <Logo className={styles.logo} showText id="logo_footer" />
          <div className={styles.description}>
            {t('footer_description')}
          </div>
        </div>
        {main?.footer?.map((item) =>
          <FooterColumn {...item} key={item.id} />
        )}
      </div>
    </footer>
  );
};

export default Footer;
