import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './header.module.css';

const Submenu = forwardRef(({ items, openSubmenu }, ref) => {
  const { i18n } = useTranslation();

  const rootClassName = classNames(
    styles.submenuWrapper,
    openSubmenu && styles.show
  );

  if (!items) return null;

  return (
    <div className={rootClassName} ref={ref}>
      <div className="container">
        {items.map((elem) => (
          <a
            key={elem.id}
            href={`/${i18n.resolvedLanguage ?? i18n.language}${elem.href}`}
            className={styles.item}
          >
            <ReactSVG src={elem.icon} wrapper="span" />
            {elem.name}
          </a>
        ))}
      </div>
    </div>
  );
});

Submenu.displayName = 'Submenu';

export default Submenu;
