import { useEffect } from 'react';

const useClickOutside = (func, ref) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && !event.target.closest('#popup-root')) func();
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line
  }, [ref]);
}

export default useClickOutside;