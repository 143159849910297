import { useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import languageDetector from '@/helpers/languageDetector';
import { Button } from '@forma/forma-ui-kit';
import useClickOutside from '@/hooks/useClickOutside';

import styles from './lang-switch-links.module.css';

const LangSwitchLinks = ({ className, onChange }) => {
  const containerRef = useRef();
  const { i18n, t } = useTranslation();
  const { pathname, query } = useRouter();
  const [ isOpen, setIsOpen ] = useState(null);

  useClickOutside(() => setIsOpen(false), containerRef);

  if (!i18n || !i18n.language || !i18n.options.locales) return null;

  const handleClickLang = (lang) => {
    languageDetector.cache(lang);
    setIsOpen(false);
    onChange();
  };

  return (
    <div className={classNames(styles.root, className)} ref={containerRef}>
      {i18n.options.locales.map(lang => {
        let href = pathname === '/' ? pathname + lang : window.location.origin + pathname.replace('[locale]', lang);
        if (query.slug) href = href.replace('[slug]', query.slug);

        return (
          <div className={styles.item} key={lang}>
            <Button
              as={href ? Link : 'button'}
              className={classNames(styles.link, i18n.language === lang && styles.active)}
              viewStyle="text"
              onClick={() => handleClickLang(lang)}
              // title={t('links.' + lang)}
              href={href}
              fullWidth
            >
              {t('languages.' + lang + '_short')}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default LangSwitchLinks;
