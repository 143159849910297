
import React, { createContext, useReducer, Dispatch, ReactElement } from 'react';

type NotificationsStateType = {
  items: {
    id: string,
    type?: 'ALERT'|'ERROR'|'SUCCESS',
    content: string,
    timeout?: number
  }[];
}

type ActionType = {
  type: 'ADD'|'REMOVE';
  payload: {
    id: string,
    type?: 'ALERT'|'ERROR'|'SUCCESS',
    content?: string,
    timeout?: number
  };
}

const initialState = {
  items: [],
}

const NotificationsContext = createContext<{
  state: NotificationsStateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (state: NotificationsStateType, action: ActionType) => {
  const { type, payload } = action;

  switch (type) {
    case 'ADD':
      return {
        ...state,
        items: [ ...state.items, { ...payload, content: payload.content ?? '' } ]
      };
    case 'REMOVE':
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== payload.id)
      };
    default:
      return state;
  }
};


const NotificationsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <NotificationsContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider, NotificationsContext };