
import React, { createContext, useReducer, Dispatch, ReactElement } from 'react';

type ActionAddType = {
  type: 'ADD';
  payload: { [key: string]: any };
}
type ActionRemoveType = {
  type: 'REMOVE';
  payload: string;
}

const SharedContext = createContext<{
  state: { [key: string]: any };
  dispatch: Dispatch<ActionAddType|ActionRemoveType>;
}>({
  state: {},
  dispatch: () => null
});

const mainReducer = (state: { [key: string]: any }, action: ActionAddType|ActionRemoveType) => {
  const { type, payload } = action;

  switch (type) {
    case 'ADD':
      return {
        ...state,
        ...action.payload
      };
    case 'REMOVE':
      const next = { ...state };
      delete(next[action.payload])
      return next;
    default:
      return state;
  }
};


const SharedProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(mainReducer, {});

  return (
    <SharedContext.Provider value={{ state, dispatch }}>
      {children}
    </SharedContext.Provider>
  );
};

export { SharedProvider, SharedContext };