import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useCookie } from 'next-cookie';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';

import styles from './cookie-notification.module.css';

const CookieNotification = () => {
  const { t } = useTranslation();
  const cookie = useCookie();
  const [ acceptCookie, setAcceptCookie ] = useState(true);
  const [ animated, setAnimated ] = useState(false);

  useEffect(() => {
    const accept = cookie.get('cookie-accept');
    if (!accept) {
      setAcceptCookie(false);
      setTimeout(() => setAnimated(true), 1000);
    }
  }, [cookie]);

  if (acceptCookie) return null;

  const handleClickSubmit = () => {
    const date = new Date();
    cookie.set('cookie-accept', true, {
      path: '/',
      expires: new Date(date.setDate(date.getDate() + 30)),
      domain: window.location.hostname
    });
    setAnimated(false);
    setTimeout(() => setAcceptCookie(true), 300);
  };

  return (
    <div className={classNames(styles.root, 'container')}>
      <div className={classNames(styles.notice, animated && styles.show)}>
        <div className={styles.text}>
          {t('cookie_notice_text')}
        </div>
        <Button className={styles.button} size="medium" onClick={handleClickSubmit}>
          {t('understand')}
        </Button>
      </div>
    </div>
  );
};

export default CookieNotification;
