import { useContext } from 'react';
import { Notifications } from '@forma/forma-ui-kit';
import { NotificationsContext } from '@/context/NotificationsContext';

const NotificationsWrapper = () => {
  const { state, dispatch } = useContext(NotificationsContext);

  return (
    <Notifications
      items={state.items}
      onRemove={(id) => dispatch({ type: 'REMOVE', payload: { id } })}
    />
  );
};

export default NotificationsWrapper;
